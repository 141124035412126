import React from 'react';
import BackgroundImage from 'gatsby-background-image';
import Image from 'gatsby-image';
import Layout from "../components/layout/layout";

import indexRecruitImage1 from "../images/recruit/recruit01.jpg";
import indexRecruitImage2 from "../images/recruit/recruit02.jpg";
import indexRecruitImage3 from "../images/recruit/recruit03.jpg";

import { graphql } from 'gatsby';

import { Helmet } from 'react-helmet';

const Recruit = ({ data }) => {
    const { indexImage } = data;
    return (
        <Layout location="recruit" metadata={ { title: "採用情報｜株式会社テストサービス" } }>
            <main className="page--recruit page">
                <section className="section-jumbotron--recruit section-jumbotron">
                    <BackgroundImage className="section-jumbotron__background" fluid={ indexImage.childImageSharp.fluid }>
                        <div className="section-jumbotron__background-overlay">
                            <div className="section-jumbotron__text-box">
                                <h1 className="section-jumbotron__header">
                                    <span className="section-jumbotron__header--main">採用情報</span>
                                </h1>
                            </div>
                        </div> 
                    </BackgroundImage>
                </section>
        <section className="section-recruit-content">
        <div className="section-recruit-image-wrap">
            <img className="imgfor1" src={ indexRecruitImage1 } alt="Card image cap" />
            <img className="imgfor2" src={ indexRecruitImage3 } alt="Card image cap" />
            <img className="imgfor3" src={ indexRecruitImage2 } alt="Card image cap" />
        </div>
            <div class="cardd"> 
                <div class="card-body">
                    <div class="card-text">
                            <h5>採用情報</h5> 
                        <hr class="txtcutter" /><br />
                        <p>
                            テストサービスでは、現在社員を募集しています。詳細をお知りになりたい方、面接をご希望の方はメール又は電話で採用担当者まで連絡をお願いします。
                        </p>
                        <h5>お問い合わせ</h5>
                        <p><a class="btn btn-primary" id="mail-button" href="mailto:t.mail-01@t-service.co.jp">メール</a>t.mail-01@t-service.co.jp</p>
                        <br />
                        <p><a class="btn btn-primary" id="phone-button" href="tel:03-3959-2343">電話</a>03-3959-2343</p>
                    </div> 
                </div>  
            </div>
            <div class="cardd" id="cardset2"> 
                <div class="card-body">
                    <div class="card-text" id="cardtxt2">
                            <h6><span class="tablecat-info">業 種</span>コンクリート検査員</h6>
                        <hr class="txtcutter2" />
                        <table>
                            <tr>
                                <td class="tablecat">仕事内容</td>
                                <td>建設工事等の現場に出かけ、コンクリートの性状試験や供試体の作製等の品質管理に関する業務を行います。
                                        未経験者も歓迎です。（OJTによる教育システム、資格取得の支援有）</td>
                            </tr>
                        </table> <br />
                        <h6><span class="tablecat-info">業 種</span>営業職　社員</h6>
                        <hr class="txtcutter2" />
                        <table>
                            <tr>
                                <td class="tablecat">仕事内容</td>
                                <td>主に大手建設会社等を対象に営業活動を行います。</td>
                            </tr>
                        </table> <br />
                        <h6><span class="tablecat-info">業 種</span>試験所試験員</h6>
                        <hr class="txtcutter2" />
                        <table>
                                <tr>
                                    <td class="tablecat">仕事内容</td>
                                    <td>社内の試験所でコンクリートや鉄筋等の強度試験を行います。</td>
                                </tr>
                            </table> <br />
                            <h6><span class="tablecat-info">業 種</span>試験事務員</h6>
                        <hr class="txtcutter2" />
                        <table>
                                <tr>
                                    <td class="tablecat">仕事内容</td>
                                    <td> データの入力や成績書の発行、発送等の業務を行います。</td>
                                </tr>
                            </table> <br />
                    </div> 
                </div>  
            </div>
        
            <hr class = "txtcutter3" />
            <hr class = "txtcutter3" />
            <hr class = "txtcutter3" />
        </section>
            </main>
        </Layout>
    )
}

export const query = graphql`
{
  indexImage: file(relativePath: {eq: "stock/placeholder_2.jpg"}) {
      childImageSharp {
          fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
          }
      }
  }
}
`;

export default Recruit;
